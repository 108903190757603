<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2V15"
      stroke="#868D9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5 6C11.5 5.6717 11.4353 5.34661 11.3097 5.04329C11.1841 4.73998 10.9999 4.46438 10.7678 4.23223C10.5356 4.00009 10.26 3.81594 9.95671 3.6903C9.65339 3.56466 9.3283 3.5 9 3.5H7C6.33696 3.5 5.70107 3.76339 5.23223 4.23223C4.76339 4.70107 4.5 5.33696 4.5 6C4.5 6.66304 4.76339 7.29893 5.23223 7.76777C5.70107 8.23661 6.33696 8.5 7 8.5H9.5C10.163 8.5 10.7989 8.76339 11.2678 9.23223C11.7366 9.70107 12 10.337 12 11C12 11.663 11.7366 12.2989 11.2678 12.7678C10.7989 13.2366 10.163 13.5 9.5 13.5H6.5C5.83696 13.5 5.20107 13.2366 4.73223 12.7678C4.26339 12.2989 4 11.663 4 11"
      stroke="#868D9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
